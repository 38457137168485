<template>
  <div v-if="coachs.length > 0" class="card">
    <div class="card-header">
      <i class="fa fa-paper-plane-o"></i>
      <span v-if="coachs.length > 1"
        >Relancer les
        <span class="font-weight-bold">{{ coachs.length }}</span> coachs en
        attente</span
      >
      <span v-else>Relancer le coach en attente</span>
    </div>

    <div class="card-block">
      <div class="row">
        <div class="col-sm-5">
          <i class="fa fa-mobile fa-lg mr-05"></i> App Coach
        </div>
        <div class="col-sm-2 text-center">
          <button
            type="button"
            class="btn btn-sm btn-success"
            :disabled="sendingNotif"
            @click="pushAttribution"
          >
            Envoyer
          </button>
        </div>
        <div class="col-sm-5 text-center">
          <i v-if="sendingNotif" class="fa fa-spinner fa-spin"></i>
          <span v-else-if="notifError" class="text-danger"
            >Echec de l'envoi...</span
          >
          <template v-else>
            <span
              v-if="
                !attribution.lastAppNotificationSentAt &&
                attribution.firstSendAt
              "
            >
              <i class="fa fa-check text-success"></i> Envoyé le
              {{ formatDateTime(attribution.firstSendAt) }} (premier rappel)
            </span>
            <span v-else-if="attribution.lastAppNotificationSentAt">
              <i class="fa fa-check text-success"></i> Envoyé le
              {{ formatDateTime(attribution.lastAppNotificationSentAt) }}
            </span>
            <span v-else> - </span>
          </template>
        </div>
      </div>
    </div>
    <div class="card-block">
      <div class="row">
        <div class="col-sm-5">
          <i class="fa fa-envelope-o fa-lg mr-05"></i> Email
        </div>
        <div class="col-sm-2 text-center">
          <button
            type="button"
            class="btn btn-sm btn-success"
            :disabled="sendingEmail"
            @click="sendEmailReminder"
          >
            Envoyer
          </button>
        </div>
        <div class="col-sm-5 text-center">
          <i v-if="sendingEmail" class="fa fa-spinner fa-spin"></i>
          <span v-else-if="emailError" class="text-danger"
            >Echec de l'envoi...</span
          >
          <template v-else>
            <span v-if="attribution.lastEmailReminderSentAt">
              <i class="fa fa-check text-success"></i> Envoyé le
              {{ formatDateTime(attribution.lastEmailReminderSentAt) }}
            </span>
            <span v-else> - </span>
          </template>
        </div>
      </div>
    </div>
    <div class="card-block">
      <div class="row">
        <div class="col-sm-5">
          <div><i class="fa fa-comment-o fa-lg mr-05"></i> SMS</div>
          <div class="text-muted">
            Prix de l'envoi : {{ coachs.length }}x{{ priceBySms }} =
            <span class="font-weight-bold">{{ sendSmsPrice }}€</span>
          </div>
        </div>
        <div class="col-sm-2 text-center">
          <button
            type="button"
            class="btn btn-sm btn-success"
            :disabled="sendingSms || remainingSms === 0"
            @click="showSmsModal = true"
          >
            Envoyer
            <el-popover
              v-if="remainingSms === 0"
              placement="top-start"
              :width="400"
              trigger="hover"
              content="Le crédit est actuellement à 0, celui-ci doit être ajouté manuellement sur le compte Brevo, ensuite ajouter manuellement le nombre de SMS restant dans l'onglet 'Paramètres' puis 'Configurations' section 'Brevo'"
            >
              <span slot="reference">
                <i class="fa fa-info-circle"></i>
              </span>
            </el-popover>
          </button>
        </div>
        <div class="col-sm-5 text-center">
          <i v-if="sendingSms" class="fa fa-spinner fa-spin"></i>
          <span v-else-if="smsError" class="text-danger"
            >Echec de l'envoi...</span
          >
          <template v-else>
            <span v-if="attribution.lastSmsReminderSentAt">
              <i class="fa fa-check text-success"></i> Envoyé le
              {{ formatDateTime(attribution.lastSmsReminderSentAt) }}
            </span>
            <span v-else> - </span>
          </template>
        </div>
      </div>
    </div>

    <AttributionSmsReminderModal
      :show="showSmsModal"
      :coachs="coachs"
      :price="priceBySms"
      :isWaitingCoachsList="isWaitingCoachsList"
      :priceBySms="priceBySms"
      :remainingSms="remainingSms"
      @send="sendSmsReminder"
      @close="showSmsModal = false"
    />
  </div>
</template>

<script>
import AttributionSmsReminderModal from "./AttributionSmsReminderModal";

export default {
  components: {
    AttributionSmsReminderModal,
  },

  props: {
    attribution: {
      type: Object,
      required: true,
    },

    coachs: {
      type: Array,
      default: () => [],
    },

    credit: {
      type: String,
      default: null,
    },

    isWaitingCoachsList: {
      type: Boolean,
      required: true,
    },

    remainingSms: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      sendingNotif: false,
      notifError: false,

      priceBySms: 0.045,
      sendingSms: false,
      smsError: false,
      showSmsModal: false,

      sendingEmail: false,
      emailError: false,
    };
  },

  computed: {
    sendSmsPrice() {
      const price = this.coachs.length * this.priceBySms;

      return price.toFixed(2);
    },
  },

  methods: {
    pushAttribution() {
      this.$confirm({
        message: `Envoyer une notification de rappel à tous les coachs "en attente de réponse" ${
          this.credit ? `pour un taux horaire de ${this.credit}€` : ""
        } ?`,
        onConfirm: async () => {
          try {
            this.notifError = false;
            this.sendingNotif = true;

            await this.$api.put(
              `/attributions/notification/${this.attribution.id}`
            );
          } catch (e) {
            this.notifError = true;
          } finally {
            this.sendingNotif = false;
          }
        },
      });
    },

    async sendSmsReminder(selected, totalSmsSent) {
      try {
        this.showSmsModal = false;
        this.smsError = false;
        this.sendingSms = true;

        const coachsSelected = () => {
          if (this.isWaitingCoachsList) {
            return this.coachs.map((coach) => coach.email);
          }

          return selected;
        };

        await this.$api.post(
          `/attributions/${this.attribution.id}/sms-reminder`,
          {
            currentPrice: this.priceBySms,
            selected: coachsSelected(),
            coachsAutomaticallySelected: this.isWaitingCoachsList,
          }
        );

        this.attribution.lastSmsReminderSentAt = new Date();
      } catch (e) {
        this.smsError = true;
      } finally {
        this.sendingSms = false;
      }

      const { data } = await this.$api
        .patch("/settings", {
          ["sms_credit_remaining"]: this.remainingSms - totalSmsSent,
        })
        .catch((e) => {
          this.$errorToast(`Erreur lors de la sauvegarde des paramètre: ${e}`);
        });

      this.$emit("updateRemainingSms", data["sms_credit_remaining"]);
    },

    sendEmailReminder() {
      this.$confirm({
        message: `Envoyer un email de rappel à tous les coachs "en attente de réponse" ${
          this.credit ? `pour un taux horaire de ${this.credit}€` : ""
        }  ?`,
        onConfirm: async () => {
          try {
            this.emailError = false;
            this.sendingEmail = true;

            await this.$api.post(
              `/attributions/${this.attribution.id}/email-reminder`
            );

            this.attribution.lastEmailReminderSentAt = new Date();
          } catch (e) {
            this.emailError = true;
          } finally {
            this.sendingEmail = false;
          }
        },
      });
    },
  },
};
</script>
