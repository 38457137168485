<template>
  <div class="card">
    <div class="card-header"><i class="icon-refresh"></i> Filtrer</div>
    <div class="card-block">
      <div class="row">
        <div class="form-group col-sm-3">
          <label>Commercial</label>
          <multiselect
            placeholder="Choisir un ou des commerciaux"
            v-model="filters.commercials"
            :options="commercials"
            label="label"
            track-by="label"
            class="custom-multiselect"
            :multiple="true"
          >
          </multiselect>
        </div>
        <div class="form-group col-sm-3">
          <label>Coach manager / Marchés public</label>
          <multiselect
            placeholder="Choisir un ou des coach managers"
            v-model="filters.coachManagers"
            :options="coachManagers"
            label="label"
            track-by="label"
            class="custom-multiselect"
            :multiple="true"
          >
          </multiselect>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <div class="row">
        <div class="col-md-6">
          <button
            type="button"
            class="btn btn-sm btn-secondary"
            @click="applyFilters"
          >
            <i class="icon-eye"></i>&nbsp; Visualiser
          </button>
        </div>

        <div class="col-md-6">
          <div class="text-right">
            <button class="btn mr-2" @click="loadLists">
              <i class="fa fa-refresh"></i> Rafraichir
            </button>
            <button
              class="btn btn-primary"
              @click="pushAll"
              :disabled="pushLoading"
              v-if="hasPermission($store.state.user, 'ATTRIBUTIONS_WRITE')"
            >
              <i class="icon-paper-plane"></i> Relance Push
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import { mapState } from "vuex";

export default {
  components: {
    Multiselect,
  },
  props: {
    default: {
      type: Object,
      required: true,
    },
    pushLoading: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      filters: {
        commercials: [],
        coachManagers: [],
      },
      commercials: [],
      coachManagers: [],
    };
  },

  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
  },

  async created() {
    this.filters = this.default;

    await this.fetchCommercials();
    await this.fetchCoachManagers();

    if (
      this.filters.commercials.length === 0 &&
      this.commercials &&
      this.commercials.filter((c) => c.value === this.user.email).length > 0 &&
      this.user.commercial &&
      this.hasPermission(this.$store.state.user, "ATTRIBUTIONS_FILTERS_WRITE")
    ) {
      this.filters.commercials.push({
        value: this.user.email,
        label: this.user.commercial.pseudo || this.user.firstName,
      });
    }

    if (
      this.filters.coachManagers.length === 0 &&
      this.coachManagers &&
      this.coachManagers.filter((c) => c.value === this.user.email).length >
        0 &&
      this.user.coachManager &&
      this.hasPermission(
        this.$store.state.user,
        "ATTRIBUTIONS_FILTERS_COACH_MANAGER_WRITE"
      )
    ) {
      this.filters.coachManagers.push({
        value: this.user.email,
        label: this.user.coachManager.pseudo || this.user.firstName,
      });
    }

    this.applyFilters();
  },

  methods: {
    applyFilters() {
      this.$emit("applyFilters", { ...this.filters });
    },

    loadLists() {
      this.$emit("loadLists");
    },

    pushAll() {
      this.$emit("pushAll");
    },

    async fetchCommercials() {
      const { data } = await this.$api.get("/users/commercials/search");

      this.commercials = data.map((c) => ({
        value: c.email,
        label: c.commercial.pseudo,
      }));
    },

    async fetchCoachManagers() {
      const { data } = await this.$api.get("/users/coach-managers");

      this.coachManagers = data.map((c) => ({
        value: c.email,
        label: c.coachManager.pseudo,
      }));
    },
  },
};
</script>

<style lang="scss" scoped>
.control-label {
  width: 100%;

  span {
    float: right;
  }
}
</style>
