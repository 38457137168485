<template>
  <div>
    <el-table :data="orders" style="width: 100%" @sort-change="sort">
      <el-table-column label="État">
        <template slot-scope="scope">
          <span class="badge" :class="getBadgeClass(scope.row)">
            {{ `${scope.row.completed} / ${scope.row.nbSessions}` }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="Grp" prop="client.companyRef" />
      <el-table-column label="Prénom" prop="client.firstName" />
      <el-table-column label="Nom" prop="client.lastName" />
      <el-table-column label="Email" prop="client" width="200">
        <template slot-scope="scope">
          {{ scope.row.client.email }}
          <a @click="(e) => goToClient(e, scope.row.client.id)" href="#">
            <i class="icon-user"></i>
          </a>
          <br />
          <div
            class="text-primary"
            @click="openParticipantsTabs(scope.row)"
            v-if="
              scope.row.orderGroup &&
              scope.row.orderGroup.orders &&
              scope.row.orderGroup.orders.length > 1
            "
          >
            Groupe
            <i class="icon-people"></i>
            <span>
              {{ scope.row.orderGroup.orders.length }}
            </span>
          </div>
          <i
            class="fa fa-google"
            v-tooltip="'Avis Google déposé.'"
            v-show="scope.row.client && scope.row.client.googleNotice"
          ></i>
          <i
            class="fa fa-star"
            v-tooltip="'Avis Trustpilot déposé.'"
            style="margin-left: 2px"
            v-show="scope.row.client && scope.row.client.trustpilotNotice"
          ></i>
        </template>
      </el-table-column>

      <el-table-column label="Téléphone" prop="client.phone" width="150" />

      <el-table-column
        label="Commande créée"
        prop="createdAt"
        width="220"
        sortable="custom"
      >
        <template slot-scope="scope">
          <span class="text-primary" @click="goToOrder(e, scope.row.id)">
            Le {{ formatDateTime(scope.row.createdAt) }}
          </span>
          <strong v-if="scope.row.packs > 0">
            <i
              class="fa fa-cart-plus"
              v-bind:class="{
                'text-success': scope.row.packsCaptured > 0,
              }"
            />
            {{ scope.row.packs }} pack<span v-if="scope.row.packs > 1">s</span>
          </strong>
        </template>
      </el-table-column>
      <el-table-column label="Coachs" prop="coachs" sortable />

      <el-table-column label="Commercial">
        <template slot-scope="scope">
          <template v-for="(rel, index) of scope.row.commercials">
            <template>
              <CommercialRel :key="`com-${index}`" :relation="rel" />
            </template>
          </template>
        </template>
      </el-table-column>

      <el-table-column label="Notes" prop="notes" width="220">
        <template slot-scope="scope">
          <button
            type="button"
            class="btn btn-sm btn-secondary"
            @click="showNotebook(scope.row)"
            v-tooltip="concatedNotes(scope.row)"
          >
            <i class="fa fa-commenting"></i>
            ({{ scope.row.notebook ? scope.row.notebook.length : 0 }}) Consulter
          </button>
        </template>
      </el-table-column>

      <el-table-column label="Type" prop="rank">
        <template slot-scope="scope">
          <span class="badge badge-warning" v-if="scope.row.rank === 'pending'">
            En attente
          </span>
          <span
            class="badge badge-success"
            v-else-if="scope.row.rank === 'fidelisation'"
          >
            Fidélisation
          </span>
          <div
            v-else-if="
              scope.row.rank === 'lost' || scope.row.rank === 'lost_other'
            "
          >
            <span class="badge badge-danger">Perdu</span><br />
            <span class="text-muted">
              {{ displayLostReason(scope.row.fidelisation.lostReason) }}</span
            >
          </div>
        </template>
      </el-table-column>

      <el-table-column
        :width="
          followed ? (ordersContainOrderWithInvalidRank ? 240 : 190) : 140
        "
        align="right"
        v-if="hasPermission($store.state.user, 'FIDELISATION_WRITE')"
        label="Actions"
      >
        <template slot-scope="scope">
          <div class="d-flex justify-content-end">
            <button
              v-if="scope.row.rank !== 'pending'"
              class="btn btn-sm btn-warning mr-1"
              @click="$emit('updateRank', scope.row, 'pending')"
              v-tooltip="{
                content: 'Permet de remettre le status \'En attente\'',
                placement: 'top',
              }"
            >
              <i class="fa fa-spinner" aria-hidden="true"></i>
            </button>
            <button
              v-if="scope.row.rank !== 'fidelisation'"
              class="btn btn-sm btn-success mr-1"
              @click="$emit('updateRank', scope.row, 'fidelisation')"
              v-tooltip="{
                content: 'Permet de changer le status en \'Fidélisé\'',
                placement: 'top',
              }"
            >
              <i class="fa fa-user-plus" aria-hidden="true"></i>
            </button>
            <button
              v-if="
                scope.row.rank !== 'lost' && scope.row.rank !== 'lost_other'
              "
              class="btn btn-sm btn-danger mr-1"
              @click="$emit('updateRank', scope.row, 'lost')"
              v-tooltip="{
                content: 'Permet de changer le status en \'Perdu\'',
                placement: 'top',
              }"
            >
              <i class="fa fa-user-times" aria-hidden="true"></i>
            </button>
            <button
              v-if="isArchived(scope.row)"
              class="btn btn-sm btn-primary"
              @click="unarchive(scope.row)"
              v-tooltip="{
                content: 'Permet de retirer des archives',
                placement: 'top',
              }"
            >
              <i class="fa fa-repeat" />
            </button>
            <button
              v-else
              class="btn btn-sm btn-primary"
              :disabled="scope.row.rank == 'pending'"
              @click="archive(scope.row)"
              v-tooltip="{
                content: `${
                  scope.row.rank == 'pending'
                    ? 'Pour archiver, changer le status en fidélisé ou perdu'
                    : 'Permet d\'archiver'
                }`,
                placement: 'top',
              }"
            >
              <i class="fa fa-archive" />
            </button>
            <button
              v-if="followed"
              class="btn btn-sm btn-danger ml-1"
              @click="validateSuiviPack(scope.row)"
              v-tooltip="{
                content:
                  'Permet de valider le suivi du pack (Le pack reviendra ultérieurement)',
                placement: 'top',
              }"
            >
              <i class="fa fa-times" />
            </button>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <Paginate
      class="mt-1"
      :page-count="page"
      :click-handler="paginationHandler"
      v-if="page > 1"
      prev-text="Précédent"
      next-text="Suivant"
      container-class="pagination"
    />

    <ModalNotebook
      :show="modalNotebook.show"
      :notebook="modalNotebook.notebook"
      :client="modalNotebook.client"
      :order="modalNotebook.order"
      @close="closeNotebook"
      @added="addNote"
      @deleted="deleteNote"
      @updated="updateNote"
    />
  </div>
</template>

<script>
import CommercialRel from "../components/CommercialRel";
import ModalNotebook from "../components/ModalNotebook";
import { mapGetters } from "vuex";
import Paginate from "vuejs-paginate";

export default {
  components: {
    CommercialRel,
    ModalNotebook,
    Paginate,
  },

  props: {
    ordersData: {
      type: Array,
      required: true,
    },
    count: {
      type: Number,
      required: true,
    },
    followed: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      modalNotebook: {
        show: false,
        notebook: [],
        client: {},
        order: {},
      },
      orders: [],
    };
  },

  watch: {
    ordersData(val) {
      this.orders = val;
    },
  },

  computed: {
    ...mapGetters({
      lostReasons: "getLostReasons",
    }),

    page() {
      return Math.ceil(this.count / 50);
    },

    ordersContainOrderWithInvalidRank() {
      return this.orders.some(
        (o) =>
          !o.rank ||
          (o.rank !== "pending" &&
            o.rank !== "fidelisation" &&
            o.rank !== "lost" &&
            o.rank !== "lost_other")
      );
    },
  },

  methods: {
    paginationHandler(page) {
      this.$emit("changePage", page);
    },

    displayLostReason(lostReason) {
      const reason = this.lostReasons.find((r) => lostReason === r.value);

      if (reason) {
        return reason.label;
      }
    },

    goToClient(e, id) {
      e && e.preventDefault();

      const route = this.$router.resolve({
        name: "client-details",
        params: {
          id,
        },
      });

      window.open(route.href, "_blank");
    },

    goToOrder(e, id) {
      e && e.preventDefault();

      const route = this.$router.resolve({
        name: "order-details",
        params: {
          id,
        },
      });

      window.open(route.href, "_blank");
    },

    openParticipantsTabs(order) {
      let ids = [order.refClient.id];

      if (order.orderGroup?.orders?.length > 0) {
        ids = [];

        for (const participantOrder of order.orderGroup.orders) {
          ids.push(participantOrder.refClient.id);
        }
      }

      ids.forEach((id) => {
        this.goToClient(null, id);
      });
    },

    getBadgeClass(order) {
      if (order.free) {
        return "badge-default";
      }

      //Payé ou impayé (traité)
      if (order.status === "payed" || order.status === "unpaid") {
        return "badge-success";
      }

      if (order.status === "refunded") {
        return "badge-info";
      }

      if (order.status === "pending") {
        return "badge-danger";
      }

      if (order.status === "partial") {
        return "badge-warning";
      }
    },

    isArchived(order) {
      return order.archived || order.archivedFidelisation;
    },

    archive(order) {
      const message =
        order.rank === "pending"
          ? 'Le pack est toujours "En attente"... archiver quand même ?'
          : "Archiver le pack ?";

      this.$confirm({
        message,
        onConfirm: async () => {
          await this.$api.put(`/orders/archive/${order.id}`, {
            archivedFidelisation: true,
          });

          this.$emit("removeEntry", order.id);
        },
      });
    },

    async unarchive(order) {
      await this.$api.put("/orders/archive/" + order.id, {
        archivedFidelisation: false,
      });

      this.$emit("removeEntry", order.id);
    },

    validateSuiviPack(order) {
      this.$confirm({
        message:
          "Cette attribution à été traitée et vous souhaitez la retirer du tableau ?",
        onConfirm: async () => {
          await this.$api.post(`/orders/fidelisation/${order.id}/followed`, {
            type: order.followedState,
          });

          this.$emit("removeEntry", order.id);
        },
      });
    },

    sort(fields) {
      if (fields.prop !== "coachs") {
        this.$emit("sort", fields);
      }
    },

    showNotebook(order) {
      this.modalNotebook.order = order;
      this.modalNotebook.client = order.client;
      this.modalNotebook.notebook = order.notebook;
      this.modalNotebook.show = true;
    },

    closeNotebook() {
      this.modalNotebook.show = false;
      this.modalNotebook.order = {};
      this.modalNotebook.client = {};
      this.modalNotebook.notebook = [];
    },

    concatedNotes(order) {
      return (
        order.notebook
          .filter((n) => n.orderId === order.id)
          .map((note) => `${note.title}: ${note.note}`)
          .join(" | ") || "Aucune note pour cette commande"
      );
    },

    addNote(note) {
      this.order = this.orders.map((o) => {
        if (o.id === note.orderId) {
          o.notebook = [note, ...o.notebook];
        }

        return o;
      });
    },

    deleteNote(noteId) {
      this.orders = this.orders.map((o) => {
        o.notebook = o.notebook.filter((n) => n.id !== noteId);

        return o;
      });
    },

    updateNote(note) {
      this.orders = this.orders.map((o) => {
        if (o.id === note.orderId) {
          o.notebook = o.notebook.map((n) => {
            if (n.id === note.id) {
              return note;
            }

            return n;
          });
        }

        return o;
      });
    },
  },
};
</script>

<style></style>
