<template>
  <div class="d-flex flex-wrap">
    <div class="col-12 col-lg-4">
      <CoachManagerStatusList :data="data.filter((client) => client.geoloc)" />
    </div>
    <div id="geocoding-map" class="col-12 col-lg-8 map relative">
      <gmap-map :center="geoloc" :zoom="6" style="width: 100%; height: 100vh">
        <gmap-marker
          v-for="(m, index) in markers"
          :icon="{
            url: require(`@/assets/markers/${m.lostReason.value}.svg`),
            scaledSize: { width: 15, height: 15 },
          }"
          :key="index"
          :position="m.position"
          :clickable="true"
          @click="changeIndex(index)"
        >
          <gmap-info-window v-if="showByIndex === index">
            <h4>{{ m.name }}</h4>
            <a @click="goToClient(m.id)" href="#">
              {{ m.email }}
            </a>
          </gmap-info-window>
        </gmap-marker>
      </gmap-map>
    </div>
  </div>
</template>

<script>
import CoachManagerStatusList from "./CoachManagerStatusList";
export default {
  components: {
    CoachManagerStatusList,
  },

  props: {
    data: { type: Array, required: true },
    currentCoach: null,
  },

  async mounted() {
    try {
      const google = await this.$gmapApiPromiseLazy();

      this.gmapIsLoaded =
        google?.maps && Object.keys(google.maps).includes("places");
    } catch (e) {
      this.gmapIsLoaded = false;
    }
  },

  data() {
    return {
      geoloc: {
        lat: 46.3969,
        lng: 2.6879,
      },
      gmapIsLoaded: false,
      showByIndex: null,
    };
  },

  computed: {
    markers() {
      return this.data
        .filter(
          (client) =>
            !!client.geoloc?.coordinates &&
            !!client.geoloc?.coordinates[0] &&
            !!client.geoloc?.coordinates[1]
        )
        .map((client) => ({
          position: {
            lat: client.geoloc.coordinates[1],
            lng: client.geoloc.coordinates[0],
          },
          ...client,
        }));
    },
  },

  methods: {
    goToClient(id) {
      const route = this.$router.resolve({
        name: "client-details",
        params: {
          id,
        },
      });

      window.open(route.href, "_blank");
    },

    changeIndex(index) {
      if (this.showByIndex && this.showByIndex !== index) {
        return (this.showByIndex = index);
      }

      return (this.showByIndex = this.showByIndex ? null : index);
    },
  },
};
</script>
