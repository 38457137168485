<template>
  <div>
    <el-table
      style="width: 100%"
      v-if="!clients"
      :data="attributionsInPage"
      :default-sort="{ prop: 'order.createdAt', order: 'descending' }"
    >
      <el-table-column label="Date" width="100" sortable prop="order.createdAt">
        <template slot-scope="scope">
          <span class="table-link" @click="clickHandler(scope.row)">{{
            formatDate(scope.row.order.createdAt)
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Société / Client" width="200">
        <template slot-scope="scope">
          <div class="d-flex">
            <span class="table-link" @click="clickHandler(scope.row)">{{
              fullName(scope.row)
            }}</span>
            <div
              class="text-primary"
              style="margin-left: 0.5rem"
              @click="openParticipantsTabs(scope.row.order)"
            >
              <i class="icon-user"></i>
              <span
                v-if="
                  scope.row.order.orderGroup &&
                  scope.row.order.orderGroup.orders &&
                  scope.row.order.orderGroup.orders.length > 1
                "
              >
                {{ scope.row.order.orderGroup.orders.length }}
              </span>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="CP"
        width="80"
        sortable
        prop="order.refClient.zipcode"
      >
      </el-table-column>
      <el-table-column label="Ville" sortable prop="order.refClient.city">
      </el-table-column>
      <el-table-column label="Pack" width="120">
        <template slot-scope="scope">
          <a href="#" class="order-link" @click="goToOrder(scope.row.order)">
            {{ formatPack(scope.row.order.session) }}
            <i
              class="fa fa-cart-plus"
              :class="
                scope.row.order.status === 'payed' ||
                scope.row.order.status === 'partial'
                  ? 'text-success'
                  : 'text-dark'
              "
            />
            <i
              v-if="hasPaymentFailed(scope.row.order.payments)"
              class="text-danger fa fa-exclamation-circle ml-0-5"
            />
          </a>
        </template>
      </el-table-column>
      <el-table-column label="Montant" width="110" sortable prop="order.price">
        <template slot-scope="scope">
          {{ formatPrice(scope.row.order.price) }}
        </template>
      </el-table-column>
      <el-table-column
        width="120"
        label="Concept"
        sortable
        prop="order.concept"
      >
        <template slot-scope="scope">
          <span v-html="conceptLogo(scope.row.order.concept, concepts)"></span>
        </template>
      </el-table-column>
      <el-table-column
        label="Commercial"
        width="130"
        sortable
        prop="order.commercials.userEmail"
      >
        <template slot-scope="scope">
          <template v-for="(rel, index) of scope.row.order.commercials">
            <template>
              <CommercialRel :key="`com-${index}`" :relation="rel" />
            </template>
          </template>
        </template>
      </el-table-column>
      <el-table-column
        label="Coach manager / Marchés public"
        width="130"
        sortable
        prop="order.coachManager.userEmail"
      >
        <template slot-scope="scope">
          <template v-for="(rel, index) of scope.row.order.coachManagers">
            <template>
              <CoachManagerRel :key="`com-${index}`" :relation="rel" />
            </template>
          </template>
        </template>
      </el-table-column>
      <el-table-column width="220" label="">
        <template
          slot-scope="scope"
          v-if="hasPermission($store.state.user, 'ATTRIBUTIONS_WRITE')"
        >
          <button
            class="btn btn-danger btn-sm"
            @click="deleteHandler(scope.row)"
          >
            <i class="icon-close"></i>
          </button>
          <button
            class="btn btn-secondary btn-sm"
            @click="clickHandler(scope.row)"
          >
            <i class="icon-user"></i>
            {{ status(scope.row) }}

            <span v-if="accept(scope.row)" class="text-success">
              <i class="icon-user-following"></i>
              {{ accept(scope.row) }}
            </span>

            <span v-if="reject(scope.row)" class="text-danger">
              <i class="icon-user-unfollow"></i>
              {{ reject(scope.row) }}
            </span>
          </button>
        </template>
      </el-table-column>
      <el-table-column
        label="1er attrib."
        width="120"
        sortable
        prop="firstSendAt"
      >
        <template slot-scope="scope">
          {{ getDelay(scope.row.firstSendAt) }}
        </template>
      </el-table-column>
      <el-table-column
        label="1er paiement"
        width="150"
        sortable
        prop="firstPaiementAt"
      >
        <template slot-scope="scope">
          {{ getDelayFirstPayment(scope.row) }}
        </template>
      </el-table-column>
      <el-table-column width="210" label="Notes Admin">
        <template slot-scope="scope">
          <NoteModal
            :notes="scope.row.order.adminNotes"
            :key="scope.row.order.id"
            permission="ATTRIBUTIONS_WRITE"
            @showComment="showComment(scope.row.order)"
            v-if="hasPermission($store.state.user, 'ATTRIBUTIONS_WRITE')"
          />
          <span v-else>{{ scope.row.order.adminNotes }}</span>
        </template>
      </el-table-column>
      <el-table-column
        width="80"
        label="Attrib"
        v-if="hasPermission($store.state.user, 'ATTRIBUTIONS_WRITE')"
      >
        <template slot-scope="scope">
          <button
            class="btn btn-sm button-transparent"
            @click="allowAttribution(scope.row)"
          >
            <i class="icon-plus"></i>
          </button>
        </template>
      </el-table-column>
      <el-table-column width="80" label="Rappel">
        <template slot-scope="scope">
          <button
            class="btn btn-sm"
            :class="{ 'btn-success': scope.row.recall }"
            @click="updateRecall(scope.row)"
            v-if="hasPermission($store.state.user, 'ATTRIBUTIONS_WRITE')"
          >
            <i v-if="scope.row.recall" class="icon-check"></i>
            <i v-else class="icon-phone"></i>
          </button>
          <span v-else>
            <i v-if="scope.row.recall" class="icon-check"></i>
            <i v-else class="icon-phone"></i>
          </span>
        </template>
      </el-table-column>
    </el-table>

    <el-table v-else :data="attributionsInPage">
      <el-table-column label="Société / Client">
        <template slot-scope="scope">
          <span class="table-link" @click="clickHandler(scope.row)">{{
            fullName(scope.row)
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="CP" sortable prop="client.zipcode">
      </el-table-column>
      <el-table-column label="Ville" sortable prop="client.city">
      </el-table-column>
      <el-table-column
        label="Commercial"
        width="130"
        sortable
        prop="client.commercials.userEmail"
      >
        <template slot-scope="scope">
          <template v-for="(rel, index) of scope.row.client.commercials">
            <template>
              <CommercialRel :key="`com-${index}`" :relation="rel" />
            </template>
          </template>
        </template>
      </el-table-column>
      <el-table-column label="">
        <template
          slot-scope="scope"
          v-if="hasPermission($store.state.user, 'ATTRIBUTIONS_WRITE')"
        >
          <button
            class="btn btn-danger btn-sm"
            @click="deleteHandler(scope.row)"
          >
            <i class="icon-close"></i>
          </button>
          <button
            class="btn btn-secondary btn-sm"
            @click="clickHandler(scope.row)"
          >
            <i class="icon-user"></i>
            {{ status(scope.row) }}

            <span v-if="accept(scope.row)" class="text-success">
              <i class="icon-user-following"></i>
              {{ accept(scope.row) }}
            </span>

            <span v-if="reject(scope.row)" class="text-danger">
              <i class="icon-user-unfollow"></i>
              {{ reject(scope.row) }}
            </span>
          </button>
        </template>
      </el-table-column>
    </el-table>

    <paginate
      :page-count="count"
      :click-handler="paginationHandler"
      v-if="count"
      prev-text="Précédent"
      next-text="Suivant"
      container-class="pagination"
    ></paginate>
  </div>
</template>

<script>
import Paginate from "vuejs-paginate";
import { mapState } from "vuex";
import moment from "moment-timezone";
import NoteModal from "./form/NoteModal";
import CommercialRel from "../components/CommercialRel";
import CoachManagerRel from "../components/CoachManagerRel";

export default {
  components: {
    Paginate,
    NoteModal,
    CommercialRel,
    CoachManagerRel,
  },
  props: {
    attributions: {
      type: Array,
      default: null,
    },
    clients: {
      type: Boolean,
      default: false,
    },
    orders: {
      type: Boolean,
      default: false,
    },
    payments: {
      type: Boolean,
      default: false,
    },
    overridePage: {
      type: Number,
      default: 1,
    },
    rows: {
      type: Number,
      default: 20,
    },
  },
  data() {
    return {
      page: this.$route.query.page || this.overridePage,
    };
  },
  computed: {
    attributionsInPage() {
      return this.attributions.slice(
        this.page * this.rows - this.rows,
        this.page * this.rows
      );
    },
    count() {
      return Math.ceil(this.attributions.length / this.rows);
    },
    ...mapState({
      concepts: (state) => state.concepts,
    }),
  },
  methods: {
    fullName(value) {
      if (value.refClient) {
        return value.refClient.fullName;
      } else if (value.client) {
        return value.client.fullName;
      } else if (value.session) {
        return value.session.client.fullName;
      } else if (value.order && value.order.refClient) {
        return value.order.refClient.fullName;
      } else if (value.order && value.order.client) {
        return value.order.client.fullName;
      }
    },
    status(value) {
      if (value.coachs.length) {
        return value.coachs.length;
      }

      return "Aucun";
    },
    accept(value) {
      return value.coachs.filter((c) => c.accepted).length;
    },
    reject(value) {
      return value.coachs.filter((c) => c.rejected).length;
    },
    paginationHandler(page) {
      this.page = page;
    },
    deleteHandler(value) {
      let type = "orders";

      if (this.clients) {
        type = "clients";
      } else if (this.payments) {
        type = "payments";
      } else if (value.order.status === "pending") {
        type = "notPayed";
      }

      this.$emit("delete", value.id, type);
    },
    clickHandler(value) {
      if (this.clients) {
        this.$emit("click", value.client, "client");
      } else {
        this.$emit("click", value.order, "order");
      }
    },
    goToClient(id) {
      const route = this.$router.resolve({
        name: "client-details",
        params: { id },
      });
      window.open(route.href, "_blank");
    },
    getDelayFirstPayment(attribution) {
      if (!attribution.order.payments || !attribution.order.payments[0]) {
        return "inconnu";
      }

      return this.getDelay(attribution.order.payments[0].createdAt);
    },
    getDelay(value) {
      if (!value) {
        return "inconnu";
      }

      const now = moment();
      const duration = moment.duration(now.diff(value));
      const hours = duration.asHours();
      const minutes = duration.asMinutes();

      return hours > 1
        ? `${Math.round(hours)} h`
        : `${Math.round(minutes)} min`;
    },
    updateRecall(value) {
      value.recall = !value.recall;

      this.$api.put(`/attributions/${value.id}`, {
        recall: value.recall,
      });

      this.$emit("moveAttribution", value);
    },

    goToOrder(order) {
      const route = this.$router.resolve({
        name: "order-details",
        params: {
          id: order.id,
        },
      });

      window.open(route.href, "_blank");
    },
    allowAttribution(attribution) {
      this.$emit("allowAttribution", attribution);
    },
    showComment(attribution) {
      this.$emit("showComment", attribution);
    },
    hasPaymentFailed(payments) {
      return (
        payments.filter((payment) => {
          return !payment.captured && payment.failed;
        }).length > 0
      );
    },

    openParticipantsTabs(order) {
      let ids = [order.refClient.id];

      if (order.orderGroup?.orders?.length > 0) {
        ids = [];

        for (const participantOrder of order.orderGroup.orders) {
          ids.push(participantOrder.refClient.id);
        }
      }

      ids.forEach((id) => {
        this.goToClient(id);
      });
    },
  },
};
</script>

<style lang="css" scoped>
.user-link {
  padding-left: 5px;
}
.table-link,
.order-link {
  cursor: pointer;
  text-decoration: none;
}
.text-dark {
  color: black;
}

.button-transparent {
  background-color: transparent;
}
.ml-0-5 {
  margin-left: 0.5rem;
}
</style>
