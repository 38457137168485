<template>
  <div class="card">
    <div class="card-header"><i class="icon-refresh"></i> Filtrer</div>
    <div class="card-block">
      <div class="row">
        <div class="form-group col-sm-4">
          <label>Date de début</label>
          <datepicker
            v-model="filters.begin"
            format="dd.MM.yyyy"
            language="fr"
            input-class="form-control"
          ></datepicker>
        </div>
        <div class="form-group col-sm-4">
          <label>Date de fin</label>
          <datepicker
            v-model="filters.end"
            format="dd.MM.yyyy"
            language="fr"
            input-class="form-control"
          ></datepicker>
        </div>
        <div class="form-group col-sm-4">
          <label>Type</label>
          <multiselect
            v-model="filters.type"
            :options="typesOptions"
            :multiple="false"
            label="label"
            track-by="label"
            class="custom-multiselect"
          >
          </multiselect>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-sm-4">
          <label>Société</label>
          <multiselect
            v-model="filters.merchants"
            :options="merchantsOptions"
            :multiple="true"
            label="label"
            track-by="label"
            class="custom-multiselect"
          >
          </multiselect>
        </div>
        <div class="form-group col-sm-4">
          <label>Concept</label>
          <multiselect
            v-model="filters.concepts"
            :options="conceptsOptions"
            :multiple="true"
            label="label"
            track-by="label"
            class="custom-multiselect"
          >
          </multiselect>
        </div>
        <div class="form-group col-sm-4">
          <label>Commercial</label>
          <multiselect
            v-model="filters.commercials"
            :options="commercialsOptions"
            :multiple="true"
            label="label"
            track-by="label"
            class="custom-multiselect"
          >
          </multiselect>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <button type="button" class="btn btn-sm btn-secondary" @click="apply">
        <i class="icon-eye"></i>&nbsp; Visualiser
      </button>
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";

import Datepicker from "vuejs-datepicker";
import Multiselect from "vue-multiselect";

export default {
  components: {
    Datepicker,
    Multiselect,
  },

  data() {
    return {
      filters: {
        begin: moment().startOf("M").format(),
        end: moment().endOf("M").format(),
        type: null,
        concepts: [],
        merchants: [],
        commercials: [],
      },
      typesOptions: [
        { label: "B2C", value: "isB2C" },
        { label: "B2B", value: "isB2B" },
        { label: "Marchés publics", value: "marketEstablishment" },
      ],
      merchantsOptions: [],
      conceptsOptions: [],
      commercialsOptions: [],
    };
  },

  created() {
    this.fetchMerchants();
    this.fetchConcepts();
    this.fetchCommercials();
  },

  methods: {
    apply() {
      this.$emit("apply", {
        ...this.filters,
        type: this.filters.type?.value || null,
        concepts: this.filters.concepts.map(({ value }) => value),
        merchants: this.filters.merchants.map(({ value }) => value),
        commercials: this.filters.commercials.map(({ value }) => value),
      });
    },

    async fetchMerchants() {
      try {
        const { data } = await this.$api.get("/merchants");

        this.merchantsOptions = data.map((merchant) => {
          return { label: merchant.name, value: merchant.id };
        });
      } catch (e) {
        this.merchantsOptions = [];
      }
    },

    async fetchConcepts() {
      try {
        const { data } = await this.$api.get("/concepts");

        this.conceptsOptions = data.map(({ name }) => ({
          label: name,
          value: name,
        }));
      } catch (e) {
        this.conceptsOptions = [];
      }
    },

    async fetchCommercials() {
      try {
        const { data } = await this.$api.get("/users/commercials/search");

        this.commercialsOptions = data.map((c) => ({
          value: c.email,
          label: c.commercial.pseudo,
        }));

        if (this.$store.state.user.commercial) {
          const commercial = this.commercialsOptions.find(
            (c) => c.value === this.$store.state.user.email
          );

          if (
            commercial &&
            !this.filters.commercials.some((c) => c.value === commercial.value)
          ) {
            this.filters.commercials.push(commercial);
          }
        }
      } catch (e) {
        this.commercialsOptions = [];
      }
    },
  },
};
</script>
